@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base styles */
html, body {
  font-family: 'Noto Sans', sans-serif;
}

/* Dark mode overrides */
html.dark,
html.dark body {
  background-color: #121212; /* matches tesla.dark.bg */
}

/* Scrollbar styles for dark mode */
html.dark ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

html.dark ::-webkit-scrollbar-track {
  background: #1E1E1E; /* matches tesla.dark.surface */
}

html.dark ::-webkit-scrollbar-thumb {
  background: #333333; /* matches tesla.dark.border */
  border-radius: 5px;
}

html.dark ::-webkit-scrollbar-thumb:hover {
  background: #444444;
}

/* Optional: Add light mode scrollbar styles for consistency */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #F4F4F4; /* matches tesla.hover */
}

::-webkit-scrollbar-thumb {
  background: #E2E3E3; /* matches tesla.border */
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #D1D1D1;
}
